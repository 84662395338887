<template>
  <div class="container mt-4" id="menu" v-if="!modoMovil">
    <div class="row">
      <div class="col-12 col-xl-2">
        <h2 class="roboto fs-1 text-center" id="t_menu"><b>MENÚ</b></h2>
      </div>
    </div>
    <div class="row">
      <Menu_pc />
    </div>
  </div>

  <div class="container mt-4" id="menu" v-else>
    <div class="row">
      <div class="col-12 col-xl-2">
        <h2 class="roboto fs-1 text-center" id="t_menu"><b>MENÚ</b></h2>
      </div>
      <div class="row">
        <Menu_movil />
      </div>
    </div>
  </div>
</template>

<script>
import Menu_pc from "./Menu_pc.vue";
import Menu_movil from "./Menu_movil.vue";

export default {
  components: {
    Menu_pc,
    Menu_movil,
  },
  data() {
    return {
      modoMovil: false,
    };
  },
  mounted() {
    if (screen.width < 1367) {
     
      this.modoMovil = true;
    } else {
      this.modoMovil = false;
    }
  },
};
</script>
<style scope>
#list-tab .active {
  background-color: #fd6f3c;
  color: white;
  border: none;
}

#t_menu {
  position: relative;
  top: 0.3rem;
  background-color: #fd6f3c;
  color: white;
  padding: 12px;
  border-radius: 5px;
  width:100%;
}
.descripcion {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  text-align: center;
  transform: translate(-50%, -50%);
  font-family: "Quicksand", sans-serif;
}
.overlay {
  background-color: #000000;
  width: 100%;
  opacity: 0.7;
  transition: 0.5s ease;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 0;
}

.contenedor_img:hover .overlay {
  height: 100%;
}

.tituloImagen {
  font-size: 12.5px;
  margin-bottom: 5px;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  background-color: #0280ba !important;

  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: #00dcff !important;
  opacity: 1 !important;
}

@media (min-width:1366px) and (max-width:1400px){
    #t_menu {
    width: 70rem;   
  }
  .list-group{
    width: 70rem !important;
    font-size: 1.5rem;
  }
}
@media (min-width: 1024px) and (max-width: 1180px){
  #t_menu {
    width: 104.5%;
    
  }
}


@media (min-width: 1024px) and (max-width: 1180px) and (min-height: 600px) and (max-height: 767px) {
  #t_menu {
    width: 104%;
  }
}
@media (min-width:719px) and (max-width:920px){
   #t_menu {
    width: 103.5%;
  }
}


@media (min-width:500px) and (max-width:719px) and (min-height:375px) and (max-height:411px){
  #t_menu {
    width: 102%;
  }
}

@media (min-width: 390px) and (max-width: 420px) {
  .descripcion {
    font-size: 13px;
  }
  .descripcionCorta {
    font-size: 14px;
  }
  #menu {
    margin-top: 10rem !important;
  }
}

@media (min-width: 360px) and (max-width: 389px) {
  .descripcion {
    font-size: 13px;
  }
  .descripcionCorta {
    font-size: 14px;
  }

  #menu {
    margin-top: 12rem !important;
  }
}
</style>
