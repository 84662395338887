<template>
 <Botones_flotantes /> 
 <Nav_fitbar /> 
   <Contenedor1></Contenedor1>
  <Contenedor2 />
   <Menu_fitbar></Menu_fitbar> 
    <Delivery /> 
   <Galeria/>
   <Opinion /> 
  <Contacto> </Contacto> 
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap";
import Nav_fitbar from "./components/Nav_fitbar.vue";
import Contenedor1 from "./components/Contenedor1.vue";
import Contenedor2 from "./components/Contenedor2.vue";
import Menu_fitbar from "./components/Menu_fitbar.vue";
import Delivery from "./components/Delivery.vue";
import Galeria from "./components/Galeria.vue";
import Opinion from "./components/Opinion.vue";
import Contacto from "./components/Contacto.vue";
import Botones_flotantes from "./components/Botones_flotantes.vue";
export default {
  name: "App",
  components: {
    Nav_fitbar,
    Contenedor1,
    Contenedor2,
    Menu_fitbar,
    Delivery,
    Galeria,
    Opinion,
    Contacto,
    Botones_flotantes,
  },
};
</script>

<style>
a {
  text-decoration: none !important;
}
.naranjaFit {
  color: #fd6f3c !important;
}
.roboto {
  font-family: "Roboto", sans-serif;
}
.quicksand {
  font-family: "Quicksand", sans-serif;
}

.cajaNaranja {
  background-color: #fd6f3c !important;
  color: white;
}
.colorLetras {
  color: #353535;
}
</style>
