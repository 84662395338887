<template>
  <div>
    <button
      id="btn_flotante"
      onclick="location.href='https://api.whatsapp.com/send?phone=9983641651'"
    >
      <img :src="require('@/assets/img/whatsapp_blanco.png')" />
      <p id="flot_p1" style="margin-top: 1rem; word-spacing: 1.3rem">
        REALIZA TU PEDIDO Y
      </p>
      <p id="flot_p2">OBTÉN EL 15% DE DESCUENTO</p>
    </button>

    <div class="alert-promo" id="alert-promo">
      <span id="cerrarPromo" @click="cerrarAlerta"><b>X</b></span>
      <div id="dot"></div>
      <span><b>Chat en vivo</b><br /></span>
      <span id="promo">Realiza tu pedido y obtén el 15% de descuento</span>
      <button
        id="ordenaAhora"
        onclick="location.href='https://api.whatsapp.com/send?phone=9983641651'"
      >
        <span style="margin-right: 9rem">Ordena ahora...</span
        ><img :src="require('@/assets/img/iconos/send.png')" />
      </button>
    </div>

    <div class="whats_flotante">
      <img
        :src="require('@/assets/img/whatsapp_flot.png')"
        onclick="location.href='https://api.whatsapp.com/send?phone=9983641651'"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "Boton_flotante",
  methods: {
    cerrarAlerta: function () {
      document.getElementById("alert-promo").style.visibility = "hidden";
    },
  },
  mounted() {
    console.log(screen.width + "x" + screen.height);
  },
};
</script>
<style scoped>
#btn_flotante {
  position: fixed;
  top: 44rem;
  left: 81rem;
  z-index: 1;
  border-radius: 50px;
  border: none;
  background-color: #0ab032;
  color: white;
  font-family: "Roboto", sans-serif;
  width: 20%;
  z-index: 3;
}
#btn_flotante:hover {
  background-color: #077521;
  margin-top: -2px;
}
#btn_flotante img {
  padding-left: 10px;
  padding-top: 10px;
  float: left;
  width: 3rem;
}
#btn_flotante p {
  text-align: justify;
  line-height: 9px;
  margin-left: 4rem;
}

/*Notificacion para celurares*/
.alert-promo {
  position: fixed;
  top: 43.5rem;
  left: 9.5rem;
  z-index: 1;
  background-color: white;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin-left: 20%;
  width: 40%;
  visibility: hidden;
}

#cerrarPromo {
  background-color: rgb(239, 239, 239);
  color: #353535;
  padding-right: 2px;
}
#promo {
  text-align: justify;
  color: #353535;
}
.whats_flotante img {
  visibility: hidden;
  width: 0%;
}
@media (min-width: 1920px) {
  #btn_flotante {
    position: fixed;
    top: 60rem;
    left: 92rem;
    z-index: 1;
    border-radius: 50px;
    border: none;
    background-color: #0ab032;
    color: white;
    font-family: "Roboto", sans-serif;
    width: 20%;
    z-index: 3;
  }
}

@media(min-width:1501px) and (max-width:1600px) and (min-height:600px) and (max-height:700px){
  #btn_flotante {
    position: fixed;
    top: 38rem !important;
    left: 72rem !important;
    z-index: 1;
    border-radius: 50px;
    border: none;
    background-color: #0ab032;
    color: white;
    font-family: "Roboto", sans-serif;
    width: 25% !important;
    z-index: 3;
  }

}
@media (min-width: 1401px) and (max-width: 1500px) {
  #btn_flotante {
    position: fixed;
    top: 50rem;
    left: 62rem;
    z-index: 1;
    border-radius: 50px;
    border: none;
    background-color: #0ab032;
    color: white;
    font-family: "Roboto", sans-serif;
    width: 30%;
    z-index: 3;
  }
}
@media (min-width:1366px) and (max-width:1400px){
   #btn_flotante {
    position: fixed;
    top: 58rem;
    left: 55rem;
    z-index: 1;
    border-radius: 50px;
    border: none;
    background-color: #0ab032;
    color: white;
    font-family: "Roboto", sans-serif;
    width: 30%;
    z-index: 3;
  }
}
@media (min-width: 1279px) and (max-width: 1365px) {
  #btn_flotante {
    position: fixed;
    top: 43rem;
    left: 55rem;
    z-index: 1;
    border-radius: 50px;
    border: none;
    background-color: #0ab032;
    color: white;
    font-family: "Roboto", sans-serif;
    width: 30%;
    z-index: 3;
  }
}
@media screen and (min-width: 1180px) and (max-width: 1280px) {
  #btn_flotante {
    position: fixed;
    top: 45rem;
    left: 50rem;
    z-index: 1;
    border-radius: 50px;
    border: none;
    background-color: #0ab032;
    color: white;
    font-family: "Roboto", sans-serif;
    width: 30%;
    z-index: 3;
  }
  #btn_flotante img {
    padding-left: 10px;
    padding-top: 10px;
    float: left;
  }
  #btn_flotante p {
    text-align: justify;
    line-height: 9px;
    margin-left: 4rem;
  }
}
@media (min-width: 1024px) and (max-width: 1179px) and (min-height: 800px) and (max-height:1367px)  {
    #btn_flotante {   
    position: fixed;
    top: 83rem !important;
    left: 37rem !important;
    z-index: 1;
    border-radius: 50px;
    border: none;
    background-color: #0ab032;
    color: white;
    font-family: "Roboto", sans-serif;
    width: 30%;
    z-index: 3;
  }
  #btn_flotante img {
    padding-left: 10px;
    padding-top: 10px;
    float: left;
  }
  #btn_flotante p {
    text-align: justify;
    line-height: 9px;
    margin-left: 4rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1179px) {
  #btn_flotante {
    position: fixed;
    top: 43rem;
    left: 37rem;
    z-index: 1;
    border-radius: 50px;
    border: none;
    background-color: #0ab032;
    color: white;
    font-family: "Roboto", sans-serif;
    width: 40%;
    z-index: 3;
  }

  #btn_flotante img {
    padding-left: 10px;
    padding-top: 10px;
    float: left;
  }
  #btn_flotante p {
    text-align: justify;
    line-height: 9px;
    margin-left: 4rem;
  }
}

@media screen and (min-width: 1024px) and (min-height: 600px) and (max-height: 767px) {
  #btn_flotante {
    position: fixed;
    top: 33rem;
    left: 42rem;
    z-index: 1;
    border-radius: 50px;
    border: none;
    background-color: #0ab032;
    color: white;
    font-family: "Roboto", sans-serif;
    width: 35%;
    z-index: 3;
  }
}
@media screen and (min-width: 820px) and (max-width: 1023px) {
  #btn_flotante {
    position: fixed;
    top: 69rem;
    left: 30rem;
    z-index: 1;
    border-radius: 50px;
    border: none;
    background-color: #0ab032;
    color: white;
    font-family: "Roboto", sans-serif;
    width: 40%;
    z-index: 3;
  }

  #btn_flotante img {
    padding-left: 10px;
    padding-top: 10px;
    float: left;
  }
  #btn_flotante p {
    text-align: justify;
    line-height: 9px;
    margin-left: 4rem;
  }
}

@media (min-width:719px) and (max-width:920px) and (min-height:375px) and (max-height:500px){
  .alert-promo {
    position: fixed;
    top: 8rem;
    left: 20.5rem;
    z-index: 1;
    background-color: white;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-left: 20%;
    width: 40%;
    visibility: visible;
    padding: 10px;
    border-radius: 5%;
    z-index: 3;
  }

  .alert-promo #cerrarPromo {
    position: relative;
    left: 18rem;
    background-color: rgb(255, 255, 255);
    color: #353535;
    padding-right: 2px;
  }
  .alert-promo #promo {
    text-align: justify;
    color: #353535;
    font-family: "Quicksand", sans-serif;
  }
  .alert-promo #dot {
    position: relative;
    top: 0.35rem;
    left: 0.75rem;
    float: left;
    width: 0.75rem !important;
    height: 0.75rem !important;
    background-color: #0ab032;
    border-radius: 50%;
  }
  .alert-promo button {
    position: absolute;
    top: 6rem;
    right: 0.1rem;
    border-radius: 5%;
    border: none;
    background-color: #ffff;
    width: 100%;
    height: 3rem;
    color: rgb(171, 171, 171);
  }
  .alert-promo button img {
    position: absolute;
    left: 18.5rem;
    width: 2rem;
  }

  .whats_flotante {
    position: fixed;
    top: 19rem;
    left: 48rem;
    z-index: 3;
  }
  .whats_flotante img {
    visibility: visible;
    width: 4rem !important;
  }

}

@media (min-width: 720px) and (max-width: 819px) {
  #btn_flotante {
    position: fixed;
    top: 58.5rem;
    left: 26rem;
    z-index: 1;
    border-radius: 50px;
    border: none;
    background-color: #0ab032;
    color: white;
    font-family: "Roboto", sans-serif;
    width: 45%;
    z-index: 3;
  }

  #btn_flotante img {
    padding-left: 10px;
    padding-top: 10px;
    float: left;
  }
  #btn_flotante p {
    text-align: justify;
    line-height: 9px;
    margin-left: 4rem;
  }
}


@media (min-width:500px) and (max-width:719px) and (min-height:375px) and (max-height:411px){
  .alert-promo {
    position: fixed;
    top: 8rem;
    left: 16rem;
    z-index: 1;
    background-color: white;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-left: 20%;
    width: 40%;
    visibility: visible;
    padding: 10px;
    border-radius: 5%;
    z-index: 3;
  }

  .alert-promo #cerrarPromo {
    position: relative;
    left: 14rem;
    background-color: rgb(255, 255, 255);
    color: #353535;
    padding-right: 2px;
  }
  .alert-promo #promo {
    text-align: justify;
    color: #353535;
    font-family: "Quicksand", sans-serif;
  }
  .alert-promo #dot {
    position: relative;
    top: 0.35rem;
    left: 0.75rem;
    float: left;
    width: 0.75rem !important;
    height: 0.75rem !important;
    background-color: #0ab032;
    border-radius: 50%;
  }
  .alert-promo button {
    position: absolute;
    top: 6rem;
    right: 0.1rem;
    border-radius: 5%;
    border: none;
    background-color: #ffff;
    width: 100%;
    height: 3rem;
    color: rgb(171, 171, 171);
  }
  .alert-promo button img {
    position: absolute;
    left: 14.5rem;
    width: 2rem;
  }

  .whats_flotante {
    position: fixed;
    top: 19rem;
    left: 37rem;
    z-index: 3;
  }
  .whats_flotante img {
    visibility: visible;
    width: 4rem !important;
  }

}


@media screen and (min-width: 411px) and (max-width: 499px) and (min-height:700px) and (max-height:1000px){
  .alert-promo {
    position: fixed;
    top: 32rem !important;
    left: 2rem;
    z-index: 1;
    background-color: white;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-left: 20%;
    width: 70%;
    visibility: visible;
    padding: 10px;
    border-radius: 5%;
    z-index: 3;
  }
  .whats_flotante {
    position: fixed;
    top: 43rem !important;
    left: 21.5rem;
    z-index: 3;
  }
  .whats_flotante img {
    visibility: visible;
    width: 4rem !important;
  }

}
@media screen and (min-width: 411px) and (max-width: 499px) {
  #btn_flotante {
    position: fixed;
    top: 51rem;
    left: 1rem;
    z-index: 1;
    border-radius: 50px;
    border: none;
    background-color: #0ab032;
    color: white;
    font-family: "Roboto", sans-serif;
    width: 15%;
    height: 4rem;
    visibility: hidden;
  }

  /*Notificación de descuento*/
  /*Notificación de descuento*/
  .alert-promo {
    position: fixed;
    top: 38rem;
    left: 2rem;
    z-index: 1;
    background-color: white;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-left: 20%;
    width: 70%;
    visibility: visible;
    padding: 10px;
    border-radius: 5%;
    z-index: 3;
  }

  .alert-promo #cerrarPromo {
    position: relative;
    left: 14.5rem;
    background-color: rgb(255, 255, 255);
    color: #353535;
    padding-right: 2px;
  }
  .alert-promo #promo {
    text-align: justify;
    color: #353535;
    font-family: "Quicksand", sans-serif;
  }
  .alert-promo #dot {
    position: relative;
    top: 0.35rem;
    left: 0.75rem;
    float: left;
    width: 0.75rem !important;
    height: 0.75rem !important;
    background-color: #0ab032;
    border-radius: 50%;
  }
  .alert-promo button {
    position: absolute;
    top: 6rem;
    right: 0.1rem;
    border-radius: 5%;
    border: none;
    background-color: #ffff;
    width: 100%;
    height: 3rem;
    color: rgb(171, 171, 171);
  }
  .alert-promo button img {
    position: absolute;
    left: 14.5rem;
    width: 2rem;
  }

  .whats_flotante {
    position: fixed;
    top: 49rem;
    left: 21.5rem;
    z-index: 3;
  }
  .whats_flotante img {
    visibility: visible;
    width: 4rem !important;
  }
}
@media screen and (min-width: 380px) and (max-width: 410px) {
  #btn_flotante {
    visibility: hidden;
  }

  /*Notificación de descuento*/
  .alert-promo {
    position: fixed;
    top: 32rem;
    left: 2rem;
    z-index: 1;
    background-color: white;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-left: 20%;
    width: 70%;
    visibility: visible;
    padding: 10px;
    border-radius: 5%;
    z-index: 3;
  }

  .alert-promo #cerrarPromo {
    position: relative;
    left: 14.5rem;
    background-color: rgb(255, 255, 255);
    color: #353535;
    padding-right: 2px;
  }
  .alert-promo #promo {
    text-align: justify;
    color: #353535;
    font-family: "Quicksand", sans-serif;
  }
  .alert-promo #dot {
    position: relative;
    top: 0.35rem;
    left: 0.75rem;
    float: left;
    width: 0.75rem !important;
    height: 0.75rem !important;
    background-color: #0ab032;
    border-radius: 50%;
  }
  .alert-promo button {
    position: absolute;
    top: 6rem;
    right: 0.1rem;
    border-radius: 5%;
    border: none;
    background-color: #ffff;
    width: 100%;
    height: 3rem;
    color: rgb(171, 171, 171);
  }
  .alert-promo button img {
    position: absolute;
    left: 14.5rem;
    width: 2rem;
  }

  .whats_flotante {
    position: fixed;
    top: 42rem;
    left: 20rem;
    z-index: 3;
  }
  .whats_flotante img {
    visibility: visible;
    width: 4rem !important;
  }
}
@media (min-width: 375px) and (max-width: 379px) {
  #btn_flotante {
    position: fixed;
    top: 38rem;
    left: 1rem;
    z-index: 1;
    border-radius: 50%;
    border: none;
    background-color: #0ab032;
    color: white;
    font-family: "Roboto", sans-serif;
    width: 4rem;
    height: 4rem;
    visibility: hidden;
  }

  /*Notificación de descuento*/
  .alert-promo {
    position: fixed;
    top: 27rem;
    left: 1rem;
    z-index: 1;
    background-color: white;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-left: 20%;
    width: 70%;
    visibility: visible;
    padding: 10px;
    border-radius: 5%;
    z-index: 3;
  }

  .alert-promo #cerrarPromo {
    position: relative;
    left: 13.8rem;
    background-color: rgb(255, 255, 255);
    color: #353535;
    padding-right: 2px;
  }
  .alert-promo #promo {
    text-align: justify;
    color: #353535;
    font-family: "Quicksand", sans-serif;
  }
  .alert-promo #dot {
    position: relative;
    top: 0.35rem;
    left: 0.75rem;
    float: left;
    width: 0.75rem !important;
    height: 0.75rem !important;
    background-color: #0ab032;
    border-radius: 50%;
  }
  .alert-promo button {
    position: absolute;
    top: 6rem;
    right: 0.1rem;
    border-radius: 5%;
    border: none;
    background-color: #ffff;
    width: 100%;
    height: 3rem;
    color: rgb(171, 171, 171);
    font-size: 15px;
  }
  .alert-promo button img {
    position: absolute;
    left: 14.5rem;
    width: 2rem;
  }

  .whats_flotante {
    position: fixed;
    top: 37rem;
    left: 19rem;
    z-index: 3;
  }
  .whats_flotante img {
    visibility: visible;
    width: 4rem !important;
  }
}
@media (min-width: 360px) and (max-width: 374px) {
  /*Boton whatsaap*/
  #btn_flotante {

    visibility: hidden;
  }

  /*Notificación de descuento*/
  .alert-promo {
    position: fixed;
    top: 31rem;
    left: 1.6rem;
    z-index: 1;
    background-color: white;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-left: 20%;
    width: 70%;
    visibility: visible;
    padding: 10px;
    border-radius: 5%;
    z-index: 3;
  }

  .alert-promo #cerrarPromo {
    position: relative;
    left: 13.5rem;
    background-color: rgb(255, 255, 255);
    color: #353535;
    padding-right: 2px;
  }
  .alert-promo #promo {
    text-align: justify;
    color: #353535;
    font-family: "Quicksand", sans-serif;
  }
  .alert-promo #dot {
    position: relative;
    top: 0.35rem;
    left: 0.75rem;
    float: left;
    width: 0.75rem !important;
    height: 0.75rem !important;
    background-color: #0ab032;
    border-radius: 50%;
  }
  .alert-promo button {
    position: absolute;
    top: 6rem;
    right: 0.1rem;
    border-radius: 5%;
    border: none;
    background-color: #ffff;
    width: 100%;
    height: 3rem;
    color: rgb(171, 171, 171);
    font-size: 14px;
  }
  .alert-promo button img {
    position: absolute;
    left: 13.5rem;
    width: 2rem;
  }

  .whats_flotante {
    position: fixed;
    top: 41rem;
    left: 18rem;
    z-index: 3;
  }
  .whats_flotante img {
    visibility: visible;
    width: 4rem !important;
  }
}


/*Celulares antiguos */

@media (min-width: 360px) and (max-width: 374px) and (max-height:670px){

 .alert-promo {
    position: fixed;
    top: 25rem;
    left: 2rem;
    z-index: 1;
    background-color: white;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-left: 20%;
    width: 68%;
    visibility: visible;
    padding: 10px;
    border-radius: 5%;
    z-index: 3;
  }

  .alert-promo #cerrarPromo {
    position: relative;
    left: 13rem;
    background-color: rgb(255, 255, 255);
    color: #353535;
    padding-right: 2px;
  }
  .alert-promo #promo {
    text-align: justify;
    color: #353535;
    font-family: "Quicksand", sans-serif;
  }
  .alert-promo #dot {
    position: relative;
    top: 0.35rem;
    left: 0.6rem;
    float: left;
    width: 0.75rem !important;
    height: 0.75rem !important;
    background-color: #0ab032;
    border-radius: 50%;
  }
  .alert-promo button {
    position: absolute;
    top: 6rem;
    right: 0.1rem;
    border-radius: 5%;
    border: none;
    background-color: #ffff;
    width: 100%;
    height: 3rem;
    color: rgb(171, 171, 171);
    font-size: 13px;
  }
  .alert-promo button img {
    position: absolute;
    left: 12rem;
    width: 2rem;
  }

  .whats_flotante {
    position: fixed;
    top: 35rem;
    left: 18rem;
    z-index: 3;
  }
  .whats_flotante img {
    visibility: visible;
    width: 4rem !important;
  }
}
</style>
