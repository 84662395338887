<template>
  <div class="col-12 col-xl-2">
    <ul class="list-group" style="width: 107%">
      <li
        class="list-group-item quicksand"
        aria-current="true"
        :class="activeSandwich"
        @click="imagenesMenu(urlImagenSandwich, 1)"
      >
        <i class="me-1">
          <img
            style="width: 4rem"
            :src="require('@/assets/img/fotos_menu/SANDWICH.png')"
          />
        </i>
        Sándwiches
      </li>
      <li
        class="list-group-item quicksand"
        @click="imagenesMenu(urlImagenHotcake, 2)"
        :class="activeHotcake"
      >
        <i class="me-1"
          ><img
            style="width: 4rem"
            :src="require('@/assets/img/fotos_menu/HOTCAKES.png')"
        /></i>
        Hot Cakes
      </li>
      <li
        class="list-group-item quicksand"
        :class="activeAvena"
        @click="imagenesMenu(urlImagenAvena, 3)"
      >
        <i class="me-1"
          ><img
            style="width: 3rem"
            :src="require('@/assets/img/fotos_menu/AVENA.png')"
        /></i>
        Avena Imperial
      </li>
      <li
        class="list-group-item quicksand"
        :class="activeOmelette"
        @click="imagenesMenu(urlImagenOmelette, 4)"
      >
        <i class="me-1"
          ><img
            style="width: 4rem"
            :src="require('@/assets/img/fotos_menu/OMELETTE.png')"
            width="50px" /></i
        >Omelettes
      </li>

      <li
        class="list-group-item quicksand"
        :class="activeEnsalada"
        @click="imagenesMenu(urlImagenEnsalada, 5)"
      >
        <i class="me-1"
          ><img
            style="width: 3.5rem"
            :src="require('@/assets/img/fotos_menu/ENSALADA.png')"
        /></i>
        Ensaladas
      </li>
      <li
        class="list-group-item quicksand"
        :class="activePlatoFuerte"
        @click="imagenesMenu(urlImagenPlatoFuerte, 6)"
      >
        <i class="me-1"
          ><img
            style="width: 3.5rem"
            :src="require('@/assets/img/fotos_menu/PLATO FUERTE.png')"
        /></i>
        Platos fuertes
      </li>

      <li
        class="list-group-item quicksand"
        :class="activeBebida"
        @click="imagenesMenu(urlImagenBebida, 7)"
      >
        <i class="me-1"
          ><img
            style="width: 4rem"
            :src="require('@/assets/img/fotos_menu/BEBIDA.png')" /></i
        >Bebidas
      </li>
    </ul>
  </div>

  <div class="modal" id="myModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title roboto">{{ tituloMenu }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cerrar"
          ></button>
        </div>
        <div class="modal-body">
          <!--Swipper-->
          <swiper
            :slides-per-view="1"
            :navigation="true"
            :space-between="30"
            :pagination="true"
            :cache="false"
            @swiper="onSwiper"
          >
            <swiper-slide v-for="item in fotosImagenes">
              <div class="contenedor_img">
                <div class="tituloImagen roboto">
                  {{ item.nombre }}
                </div>
                <div class="quicksand">{{ descripcionGeneral }}</div>
                <img :src="urlFotos + item.foto" />

                <div class="overlayM" :class="aparecer">
                  <div class="descripcionM">
                    {{ item.descripcion }}
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="cerrar"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Modal } from "bootstrap";
SwiperCore.use([Navigation, Pagination]);
export default {
  name: "Menu movil",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiper: null,
      urlFotos: "https://187.188.16.29:4431/paginas/fitbar/",
      activeSandwich: "active",
      activeHotcake: "",
      activeAvena: "",
      activeOmelette: "",
      activeEnsalada: "",
      activePlatoFuerte: "",
      activeBebida: "",
      descripcionMenu: "",
      tituloMenu: "",
      fotosImagenes: [],
      descripcionGeneral: "",
      aparecer: "",
    };
  },
  computed: {
    ...mapState([
      "urlImagenSandwich",
      "urlImagenHotcake",
      "urlImagenAvena",
      "urlImagenPlatoFuerte",
      "urlImagenOmelette",
      "urlImagenEnsalada",
      "urlImagenBebida",
    ]),
  },
  destroyed() {
    console.log(this.fotosImagenes);
  },
  methods: {
    ...mapMutations(["getFotos"]),
    ...mapActions(["obtenerImagenes"]),
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    imagenesMenu(fotosImagenes, tipo) {
      this.fotosImagenes = fotosImagenes;
      console.log(fotosImagenes);
      let myModal = new Modal(document.getElementById("myModal"));

      switch (tipo) {
        case 1:
          this.descripcionGeneral = "Acompañado con ensalada mixta.";
          this.tituloMenu = "SÁNDWICH";

          this.activeSandwich = "active";
          this.activeAvena = "";
          this.activeHotcake = "";
          this.activeOmelette = "";
          this.activePlatoFuerte = "";
          this.activeEnsalada = "";
          this.activeBebida = "";

          myModal.show();

          break;
        case 2:
          this.descripcionGeneral = "";
          this.tituloMenu = "HOT CAKES";
          this.activeSandwich = "";
          this.activeAvena = "";
          this.activeHotcake = "active";
          this.activeOmelette = "";
          this.activePlatoFuerte = "";
          this.activeEnsalada = "";
          this.activeBebida = "";

          myModal.show();

          break;
        case 3:
          this.descripcionGeneral = "";
          this.tituloMenu = "AVENA";
          this.activeSandwich = "";
          this.activeAvena = "active";
          this.activeHotcake = "";
          this.activeOmelette = "";
          this.activePlatoFuerte = "";
          this.activeEnsalada = "";
          this.activeBebida = "";
          myModal.show();

          break;
        case 4:
          this.descripcionGeneral =
            "Un complemento a elegir: puré de camote o papa, arroz blanco o integral y espaguetti regular o integral.";
          this.tituloMenu = "OMELETTE";
          this.activeSandwich = "";
          this.activeAvena = "";
          this.activeHotcake = "";
          this.activeOmelette = "active";
          this.activePlatoFuerte = "";
          this.activeEnsalada = "";
          this.activeBebida = "";
          myModal.show();
          break;
        case 5:
          this.descripcionGeneral = "";
          this.tituloMenu = "ENSALADA";
          this.activeSandwich = "";
          this.activeAvena = "";
          this.activeHotcake = "";
          this.activeOmelette = "";
          this.activePlatoFuerte = "";
          this.activeEnsalada = "active";
          this.activeBebida = "";
          myModal.show();
          break;
        case 6:
          this.descripcionGeneral =
            "Un complemento a elegir: puré de camote o papa, arroz blanco o integral y espaguetti regular o integral.";
          this.tituloMenu = "PLATO FUERTE";
          this.activeSandwich = "";
          this.activeAvena = "";
          this.activeHotcake = "";
          this.activeOmelette = "";
          this.activePlatoFuerte = "active";
          this.activeEnsalada = "";
          this.activeBebida = "";
          myModal.show();
          break;
        case 7:
          this.descripcionGeneral = "";
          this.tituloMenu = "BEBIDA";
          this.activeSandwich = "";
          this.activeAvena = "";
          this.activeHotcake = "";
          this.activeOmelette = "";
          this.activePlatoFuerte = "";
          this.activeEnsalada = "";
          this.activeBebida = "active";
          myModal.show();
          break;

        default:
          break;
      }
    },

    cerrar() {
      this.fotosImagenes = []; //Borra imagenes de cache
      this.swiper.slideTo(0);
    },
  },
};
</script>
<style scoped>
.list-group .active {
  background-color: #fd6f3c;
  color: white;
  border: none;
}
.tituloImagen {
  font-size: 1.5rem !important;
}
.descripcion {
  font-size: 1.2rem;
}
.descripcionM {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  text-align: center;
  transform: translate(-50%, -50%);
  font-family: "Quicksand", sans-serif;
}
.overlayM {
  background-color: #000000;
  width: 100%;
  opacity: 0.7;
  transition: 0.5s ease;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 0;
}

.contenedor_img:hover .overlayM {
  animation-name: descripcion;
  animation-duration: 10s;
}

@keyframes descripcion {
  from {
    height: 0%;
  }
  5% {
    height: 100%;
  }
  40% {
    height: 100%;
  }

  to {
    height: 0%;
  }
}
</style>
