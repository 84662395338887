<template>
  <div class="container" id="inicio">
    <div class="row">
      <div class="col-sm-6 col-md-6 mt-5 p-5" id="contenedor1">
        <img
          class="img-fluid"
          :src="require('@/assets/img/Logo portada.png')"
        />

        <span class="mt-3" id="descripcion"
          >COMIDA SALUDABLE, PARA TU ESTILO DE VIDA FIT.</span
        >
      </div>
      <div class="col-sm-6 col-md-6">
        <img
          id="portada"
          :src="require('@/assets/img/portada.png')"
          alt="Comida fitbar"
          class="img-fluid"
        />
      </div>
      <div class="col-md-3">
        <button
          class="button btn btn-primary btn-lg"
          id="btnOrdenar"
          onclick="location.href='https://api.whatsapp.com/send?phone=9983641651'"
        >
          ORDENE AHORA!
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Contenedor1",
  data() {
    return {
      titulo: "Contador Vuex:",
    };
  },
  computed: {
    ...mapState(["urlImagen"]),
  },
};
</script>
<style scoped>
#contenedor1 {
  position: relative;
  top: 60px;
}
#contenedor1 span {
  display: block !important;
}
#btnOrdenar {
  position: absolute;
  top: 400px;
  border: 5px solid #fd6f3c;
  background-color: #fd6f3c !important;
  margin-top: 150px;
  margin-left: 2rem;
}
#portada {
  position: relative;
  top: 90px;
}
#descripcion {
  font-family: "Quicksand", sans-serif;
}

@media screen and (min-width: 1180px) and (max-width: 1280px) {
  #btnOrdenar {
    position: absolute;
    top: 380px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1179px) {
  #btnOrdenar {
    position: absolute;
    top: 20rem;
  }
}
@media screen and (min-width: 719px) and (max-width: 1023px) {
  #btnOrdenar {
    position: absolute;
    top: 260px;
  }
  #contenedor1 {
    position: relative;
    top: 10px;
  }
}

@media (min-width: 667px) and (max-width: 719px) {
  #contenedor1 {
    margin-bottom: 5rem !important;
    margin-top: -2rem !important;
  }
  #btnOrdenar {
    position: absolute;
    top: 9rem;
  }
}
@media screen and (min-width: 500px) and (max-width: 666px) {
  #contenedor1 {
    margin-bottom: 5rem !important;
    margin-top: -2rem !important;
  }
  #btnOrdenar {
    position: absolute;
    top: 18rem;
  }
}
@media (max-width: 499px) {
  #btnOrdenar {
    position: absolute;
    top: 660px;
  }
  #contenedor1 {
    position: relative;
    top: 10px;
  }
  #portada {
    position: relative;
    top: 5px;
  }
}
</style>
