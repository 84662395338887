<template>
  <!--Delivery-->
  <div class="container-fluid" id="delivery">
    <div class="row" id="imagenes">
      <div class="col-1 col-sm-1"></div>
      <div class="col-2 col-sm-2 col-md-2">
        <img :src="require('@/assets/img/NEW Delivery_1.png')" />
      </div>
      <div class="col-2 col-sm-2 col-md-2">
        <img :src="require('@/assets/img/NEW Delivery_2.png')" />
      </div>
      <div class="col-2 col-sm-2 col-md-2">
        <img :src="require('@/assets/img/NEW Delivery_1.png')" />
      </div>
      <div class="col-2 col-sm-2 col-md-2">
        <img :src="require('@/assets/img/NEW Delivery_2.png')" />
      </div>

      <div class="col-2 col-sm-2 col-md-2">
        <img :src="require('@/assets/img/NEW Delivery_1.png')" />
      </div>
    </div>
  </div>
  <!--Logos platadorma-->
  <div class="container-fluid" id="contenedor4">
    <div class="row">
      <div class="col-md-12">
        <span class="fs-1" id="fitbar2"><b>THE FIT BAR</b></span>
        <span><b>DELIVERY</b></span>
      </div>
    </div>
  </div>
  <br />
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p class="fs-5 text-center quicksand colorLetras">
          Encuéntranos a través de tu plataforma favorita
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div>
          <div>
            <a href="https://api.whatsapp.com/send?phone=9983641651">
              <img
                id="whatsapp"
                class="img-fluid"
                :src="require('@/assets/img/whatsaap.png')"
              />
            </a>
            <img
              class="img-fluid"
              id="plataformas"
              :src="require('@/assets/img/logosPlataformas.png')"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-2">
        <div class="lead mb-3">
          <a
            class="naranjaFit quicksand"
            id="link"
            href="https://api.whatsapp.com/send?phone=9983641651"
            >HAZ TU PEDIDO AQUÍ</a
          >
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p class="fs-3 text-center quicksand colorLetras">
          Realiza tu pedido a través de WhatsApp y obtén el 15% DE DESCUENTO en
          el total de tu compra.
        </p>
      </div>
    </div>
  </div>
</template>

<style>
#contenedor4 {
  text-align: center;
  background-color: #fd6f3c !important;
  color: white;
  margin-top: -5.5rem;
  padding-top: 6rem;
  height: 200px;
}
#contenedor4 span {
  display: block !important ;
}

#imagenes {
  margin-top: 70px;
}
#whatsapp {
  width: 260px;
  height: 260px;
  margin-top: -15px;
}
#link {
  text-decoration: none;
  font-size: 17px;
  margin-left: 20px;
}
#whatsapp:hover {
  margin-top: -1.5rem;
}

@media (min-width: 1024px) and (max-width: 1400px) {
  #whatsapp {
    margin-top: -5px;
    width: 10rem;
    height: 10rem;
  }
  #plataformas {
    width: 40rem;
    height: 12rem;
  }
  #link {
    font-size: 13px;
    margin-left: -1rem;
    color: #fd6f3c !important;
  }
  #imagenes img {
    width: 11rem;
    height: 10rem;
    padding-left: 15px !important;
  }
  #imagenes {
    position: relative;
    right: 25px;
  }
}

@media screen and (min-width: 820px) and (max-width: 1023px) {
  #whatsapp {
    margin-top: -5px;
    width: 7.5rem;
    height: 9rem;
  }
  #plataformas {
    width: 34rem;
    height: 11rem;
  }
  #link {
    font-size: 13px;
    margin-left: -1rem;
    color: #fd6f3c !important;
  }

  #imagenes img {
    width: 170px;
    height: 170px;
  }

  #imagenes :nth-child(3) {
    margin-left: 2.5rem;
  }
  #imagenes :nth-child(4) {
    margin-left: 2.5rem;
  }
  #imagenes :nth-child(5) {
    margin-left: 2.5rem;
  }

  #imagenes :nth-child(6) {
    visibility: hidden;
  }

  #imagenes {
    position: relative;
    right: 25px;
  }
  #contenedor4 {
    margin-top: -13rem !important;
    padding-top: 4rem;
  }
}
@media (min-width: 720px) and (max-width: 819px) {
  #whatsapp {
    margin-top: -5px;
    width: 8rem;
    height: 7.5rem;
  }
  #plataformas {
    width: 30rem;
    height: 9rem;
  }
  #link {
    font-size: 15px;
    margin-left: -1rem;
    color: #fd6f3c !important;
  }
  #imagenes img {
    width: 170px;
    height: 170px;
  }

  #imagenes :nth-child(3) {
    margin-left: 3rem;
  }
  #imagenes :nth-child(4) {
    margin-left: 3rem;
  }
  #imagenes :nth-child(5) {
    margin-left: 3rem;
  }

  #imagenes :nth-child(6) {
    visibility: hidden;
  }

  #imagenes {
    position: relative;
    right: 25px;
  }
  #contenedor4 {
    margin-top: -14rem !important;
    padding-top: 4rem;
  }
}





@media (min-width: 500px) and (max-width: 719px) {
  #whatsapp {
    margin-top: -5px;
    width: 6.25rem;
    height: 6.5rem;
  }
  #plataformas {
    width:26rem;
    height: 8rem;
  }
  #link {
    font-size: 11px;
    color: #fd6f3c !important;
    margin-left: -0.1rem;
  }
  #imagenes img {
    width: 120px;
    height: 120px;
  }

  #imagenes :nth-child(3) {
    margin-left: 2rem;
  }
  #imagenes :nth-child(4) {
    margin-left: 2rem;
  }

  #imagenes :nth-child(5) {
    margin-left: 2rem;
  }
  #imagenes :nth-child(6) {
    visibility: hidden;
  }

  #imagenes {
    position: relative;
    right: 25px;
  }
  #contenedor4 {
    margin-top: -10rem !important;
    padding-top: 4rem;
  }
}

@media screen and (min-width: 380px) and (max-width: 499px) {
  #whatsapp {
    margin-top: -5px;
    width: 80px;
    height: 80px;
  }
  #plataformas {
    width: 280px;
    height: 90px;
  }

  #link {
    font-size: 10px;
    color: #fd6f3c !important;
    margin-left: -0.1rem;
  }
  #imagenes img {
    width: 120px;
    height: 120px;
  }

  #imagenes :nth-child(3) {
    margin-left: 3.5rem;
  }
  #imagenes :nth-child(4) {
    margin-left: 3.5rem;
  }

  #imagenes :nth-child(5) {
    visibility: hidden;
  }
  #imagenes :nth-child(6) {
    visibility: hidden;
  }

  #imagenes {
    position: relative;
    right: 25px;
  }
  #contenedor4 {
    margin-top: -10rem !important;
    padding-top: 4rem;
  }
}

@media (min-width: 360px) and (max-width: 379px) {
  #whatsapp {
    margin-top: -5px;
    width: 70px;
    height: 80px;
  }
  #plataformas {
    width: 246px;
    height: 90px;
  }

  #link {
    font-size: 10px;
    color: #fd6f3c !important;
    margin-left: -0.5rem;
  }

  #imagenes img {
    width: 100px;
    height: 100px;
  }

  #imagenes :nth-child(3) {
    margin-left: 3.5rem;
  }
  #imagenes :nth-child(4) {
    margin-left: 3.5rem;
  }

  #imagenes :nth-child(5) {
    visibility: hidden;
  }
  #imagenes :nth-child(6) {
    visibility: hidden;
  }

  #imagenes {
    position: relative;
    right: 25px;
  }

  #contenedor4 {
    margin-top: -8.5rem !important;
    padding-top: 4rem;
  }
}
</style>
