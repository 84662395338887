<template>
  <div class="container" id="galeria">
    <div class="row">
      <div class="col-lg-12 col-md-12" >
        <div
          id="carrusel"
          class="carousel slide"
          data-bs-ride="carousel"  
          
        >
          <div class="carousel-inner" id="imgInstagram"></div>

          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Galeria",
  data() {
    return {
      fotosInstagram: [],
      active: "active",
    };
  },
  
  //IGQWRQY3pleUhMb0xnZAXBqaHhPUU1mbFg3cDAwQUY1Qk1fbnJmM2hqLThxRzRYMEtvdUJDcjhPc09TVFRreGZAxOFoxZAE9pdVRfMWdqQmZAxNkE5bG9LczZAPNVptcjNjSHVfY0xmamozSW1aTVNTdWNiWklleXhDT2sZD

  created() {
    const token = process.env.VUE_APP_API_INSTAGRAM;
    //const token = "IGQWRQY3pleUhMb0xnZAXBqaHhPUU1mbFg3cDAwQUY1Qk1fbnJmM2hqLThxRzRYMEtvdUJDcjhPc09TVFRreGZAxOFoxZAE9pdVRfMWdqQmZAxNkE5bG9LczZAPNVptcjNjSHVfY0xmamozSW1aTVNTdWNiWklleXhDT2sZD";
  
    var url =
      "https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url&access_token=" +
      token;

    fetch(url)
      // Exito
      .then((response) => response.json()) // convertir a json
      .then(function (json) {
       
        var i = 0;
        let dataInstagram = [];
        let data = json.data;
        var id = [];
        var url = [];
        let max = 0;
        var imgInstagram = document.getElementById("imgInstagram");
        console.log(data);
        for (const iterator of data) {
          let descripcionImagen = iterator.caption;
          //console.log(descripcionImagen.includes('#web'));
          if (iterator.media_type == "IMAGE") {
            //  imgInstagram.innerHTML+=`<div class="swiper-slide"> <img class="w-100" src="${iterator.media_url}"/></div>`;
            //console.log(imgInstagram);
         
            //if (descripcionImagen.includes("#web")) {
              
              id[i] = iterator.id;
              url[i] = iterator.media_url;
              dataInstagram.push({
                id: id[i],
                url: url[i],
              });
         
         
         //   }
          }
        }

        if (dataInstagram.length < 20) {
          max = dataInstagram.length;
        } else {
          max = 20;
        }
        // crear arreglo para verificar numero aleatorio
        var numAleatorios = [];
        var existe = false;
        var i = 0;

        while (i != max) {
          var indice = random(0, dataInstagram.length - 1);
          for (let index = 0; index < numAleatorios.length; index++) {
            if (numAleatorios[index] == indice) {
              existe = true;
              break;
            } else {
              existe = false;
            }
          }
          if (!existe) {
            if (i == 0) {
              imgInstagram.innerHTML += `
               <div class='carousel-item active' id='item'>
                 <img  class='d-block img-fluid w-100 ' src="${dataInstagram[indice].url}" > 
               </div>`;
            } else {
              imgInstagram.innerHTML += `
          <div class='carousel-item' id='item'>
            <img  class='d-block img-fluid w-100' src="${dataInstagram[indice].url}" > 
          </div>`;
            }
            numAleatorios[i] = indice;

            i++;
          }
        }
      }) //imprimir los datos en la consola
      .catch((err) => console.log("Solicitud fallida", err));
  },
};
function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
</script>
<style scoped>
#imgInstagram{
  overflow: hidden;
 
  
}


#carrusel{
  height:80%; 
  width:70%; 
  margin-left:12rem
}
@media(min-width:1024px) and (max-width:1250px){
  #imgInstagram{
  overflow: hidden;
 

}
}

@media(min-width:667px) and (max-width:1023px){
  #imgInstagram{
  overflow: hidden;
 
 
}
#carrusel{
  margin-left:5rem;
  width:78%;
  
}
}


@media(min-width:360px) and (max-width:666px){
  #imgInstagram{
  overflow: hidden;

 
}
#carrusel{
  margin-left:0rem;
  width:100%;
}
}



</style>