<template>
  <div class="container-fluid pb-3 cajaNaranja" id="contacto">
    <div class="row">
      <div class="col-md-12 col-lg-5 mb-4 pt-3">
        <iframe
          id="mapa"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d930.3674593802618!2d-86.85617427280953!3d21.13369258956276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c2b0f8406a891%3A0x85bcf77f22d87d4f!2sThe%20Fit%20Bar!5e0!3m2!1ses!2smx!4v1655999946812!5m2!1ses!2smx"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div class="col-md-12 col-lg-12 col-xl-6 mt-5" id="direccion">
        <div>
          <h2 class="fs-3 roboto">CONTACTO</h2>
          <span class="d-block quicksand">Horarios</span>
          <span class="d-block quicksand">Lunes a viernes</span>
          <span class="d-block quicksand">7:00 am a 8:00 pm</span>
          <span class="d-block quicksand">Sábado</span>
          <span class="d-block quicksand"> 7:00 am a 4:00 pm</span>
          <span class="d-block quicksand"
            >Pedidos al <b>(998) 364 1651</b></span
          >
          <span class="d-block quicksand mb-3"
            >The Fit Bar, Plaza Zafiro, Av. Las Torres, Smz 509, Av. Kinik, La
            Luna, 77533 Cancún Q.R</span
          >
        </div>
        <div class="row mt-3">
          <div class="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1">
            <a href=" https://www.facebook.com/fitbarcancun"
              ><img
                style="width: 50px"
                :src="require('@/assets/img/facebook.png')"
              />
            </a>
          </div>
          <div class="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1">
            <a href="https://www.instagram.com/thefitbarcancun/"
              ><img
                style="width: 50px"
                :src="require('@/assets/img/instagram.png')"
            /></a>
          </div>
          <div class="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1">
            <img
              style="width: 50px"
              :src="require('@/assets/img/Tiktok.png')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
#mapa {
  width: 40rem;
  height: 28rem;
}
@media (min-width:1366px) and (max-width:1400px){
  #direccion{
  
    position: relative;
    left:5rem;
  }

}
@media screen and (min-width: 1024px) and (max-width: 1179px) {
  #mapa {
    width: 1050px;
    padding-right: 100px;
  }
}
@media screen and (min-width: 820px) and (max-width: 1023px) {
  #mapa {
    width: 750px;
  }
}
@media (min-width: 720px) and (max-width: 819px) {
  #mapa {
    width: 720px;
  }
}
@media screen and (min-width: 500px) and (max-width: 666px) {
  #mapa {
    width: 480px;
  }
}
@media (min-width: 401px) and (max-width: 665px) {
  #mapa {
    width: 380px;
  }
}
@media screen and (min-width: 360px) and (max-width: 400px) {
  #mapa {
    width: 360px;
  }
}

@media (min-width: 360px) and (max-width: 374px) and (max-height:670px){
    #mapa {
    width: 340px;
    height: 340px;
  }
}
</style>
