import { createStore } from 'vuex'

export default createStore({
  state: {
    urlImagenSandwich:[],
    urlImagenHotcake:[],
    urlImagenAvena:[],
    urlImagenOmelette:[],
    urlImagenPlatoFuerte:[],
    urlImagenEnsalada:[],
    urlImagenBebida:[],

  },

  mutations: {
    getFotos(state,urlFoto){
    for (const iterator of urlFoto) {
          switch (iterator.tipo){
           case 1:
            state.urlImagenSandwich.push(iterator);
            break;
            case 2:
              state.urlImagenHotcake.push(iterator);
              break;
            case 3:
              state.urlImagenAvena.push(iterator);
              break;
            case 4:
              state.urlImagenOmelette.push(iterator);
              break;
            case 5:
              state.urlImagenPlatoFuerte.push(iterator);
              break;
            case 6:
              state.urlImagenBebida.push(iterator);
              break;
            case 7:
              state.urlImagenEnsalada.push(iterator);
              break;
            default:
              console.log("no existe la imagen");
              break;    
          }   
    }
    
    }
  },
  actions: {
    obtenerImagenes: async function({commit}){
      const data = await fetch('https://187.188.16.29:4431/paginas/fitbar/getMenu.php');
      const fotos = await data.json();
      commit('getFotos',fotos);
    }


  },

})
