<template>
  <div>
    <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-light">
      <div class="container-fluid">
        <a href="#"></a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto mb-lg-0">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="activeInicio"
                @click="clickNav(1)"
                href="#inicio"
                >INICIO</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="activeMenu"
                @click="clickNav(2)"
                href="#menu"
                >MENÚ</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="activeGaleria"
                @click="clickNav(3)"
                href="#galeria"
                >GALERÍA</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="activeSdomicilio"
                @click="clickNav(4)"
                href="#delivery"
                >SERVICIO A DOMICILIO</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="activeContacto"
                @click="clickNav(5)"
                href="#contacto"
                >CONTACTO</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { switchCase } from "@babel/types";

export default {
  data() {
    return {
      activeInicio: "active",
      activeMenu: "",
      activeGaleria: "",
      activeSdomicilio: "",
      activeContacto: "",
    };
  },
  methods: {
    clickNav(tipo) {

      console.log(document.getElementById('navbarNav'));
    let clases = document.getElementById('navbarNav').classList;
    let  tieneClase= clases.contains('show');
    console.log(tieneClase);
    if(tieneClase){
      clases.remove('show');
    }
    
      switch (tipo) {
        case 1:
          this.activeInicio = "active";
          (this.activeMenu = ""), (this.activeGaleria = "");
          this.activeSdomicilio = "";
          this.activeContacto = "";
          break;
        case 2:
          this.activeInicio = "";
          (this.activeMenu = "active"), (this.activeGaleria = "");
          this.activeSdomicilio = "";
          this.activeContacto = "";
          break;
        case 3:
          this.activeInicio = "";
          (this.activeMenu = ""), (this.activeGaleria = "active");
          this.activeSdomicilio = "";
          this.activeContacto = "";
          break;
        case 4:
          this.activeInicio = "";
          (this.activeMenu = ""), (this.activeGaleria = "");
          this.activeSdomicilio = "active";
          this.activeContacto = "";
          break;
        case 5:
          this.activeInicio = "";
          (this.activeMenu = ""), (this.activeGaleria = "");
          this.activeSdomicilio = "";
          this.activeContacto = "active";
          break;

        default:
          console.log("No existe este elemento en el menu");
          break;
      }
    },
  },
};
</script>
