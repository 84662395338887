<template>
  <div>
    <div class="container mt-5">
      <div class="row">
        <div class="col-12 text-center">
          <p class="fs-1 naranjaFit roboto">LO QUE OPINAN</p>
          <p class="quicksand fs-4 colorLetras">DE NOSOTROS</p>
        </div>
      </div>
      <div class="row mb-5" id="imgFotos"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  created() {
    var urlFotos = "https://187.188.16.29:4431/paginas/fitbar/";
    axios({
      method: "get",
      url: "https://187.188.16.29:4431/paginas/fitbar/getOpiniones.php",
      responseType: "json",
    }).then(function (response) {
      var imgOpinion = document.getElementById("imgFotos");
      for (const iterator of response.data) {
        imgOpinion.innerHTML += `<div class="col-md-4 col-lg-4 col-xl-4"><img class="img-fluid ms-1" src="${
          urlFotos + iterator.foto
        }"/></div>`;
      }
    });
  },
};
</script>
<style></style>
