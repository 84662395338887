<template>
  <div class="container-fluid text-white mt-4" id="contenedor2">
    <div class="row">
      <div class="col-xl-3 d-none d-xl-block">
        <img
          class="img-fluid"
          :src="require('@/assets/img/verduras_IZQ.png')"
        />
      </div>
      <div class="col-xl-6 mt-5">
        <p class="quicksand">
          En nuestro menú tenemos deliciosos y balanceados opciones para
          mantenerte saludable.
        </p>

        <p class="quicksand">
          Somos un restaurante de comida para personas que cuidan su
          alimentación y tienen un estilo de vida fitness.
        </p>

        <p class="quicksand">¡REALIZA TU PEDIDO!</p>

        <div class="row">
          <div class="col-xl-12 mb-4">
            <div id="video" class="mt-5">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/pd9ePJJvY5I?controls=0"
                title="Conocenos"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 d-none d-xl-block">
        <img
          class="img-fluid"
          :src="require('@/assets/img/verduras_DER.png')"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
#contenedor2 {
  margin-top: -50px;
  background-color: #fd6f3c;
  text-align: center;
}
p{
  font-size: 1.2rem;
}
@media (min-width: 1024px) and (max-width: 1200px) {
  #contenedor2 {
    margin-top: 10rem !important;
  }
}
@media screen and (min-width: 720px) and (max-width: 1023px) {
  iframe {
    width: 350px;
  }
  #contenedor2 {
    margin-top: 7rem !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 666px) {
  iframe {
    width: 450px;
  }
  #contenedor2 {
    margin-top: 3rem !important;
  }
}
@media screen and (min-width: 380px) and (max-width: 499px) {
  iframe {
    width: 360px;
  }
  #contenedor2 {
    position: relative;
    top: 140px;
  }
}

@media (min-width: 360px) and (max-width: 379px) {
  #contenedor2 {
    position: relative;
    top: 175px;
  }
  iframe {
    width: 350px;
  }
}
@media (min-width: 360px) and (max-width: 374px) and (max-height:670px){

  iframe {
    width: 340px;
  }
}
</style>
